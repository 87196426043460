<template>
  <b-tab
      :disabled="isDisabledTab"
  >
    <template #title>
      <feather-icon
          icon="FileIcon"
          size="21"
      />
      <strong>
        {{ $t('tab.title.files') }}
        &nbsp<b-badge v-if="dangerCnt > 0" variant="danger">{{ dangerCnt }}</b-badge>
      </strong>
    </template>
    <div>
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <validation-observer ref="postFileValidationObserver">
          <b-form>
          <b-row
              v-for="(item, index) in tabData"
              :key="item.id"
              sm="2"
              class="mb-1"
          >
            <b-col
                sm="3"
            >
              <feather-icon
                  v-if="item.saved"
                  icon="CheckIcon"
                  size="15"
                  class="text-success"
              />
              <feather-icon
                  v-else
                  icon="XIcon"
                  size="15"
                  class="text-warning"
              />
              &nbsp
              {{ item.name }}
            </b-col>
            <b-col
                sm="7"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('form.file').toLowerCase()"
                  rules=""
                  vid="title"
              >
                <b-form-file
                    v-model="item.file"
                    size="sm"
                    :placeholder="item.saved ? $t('label.fileInputFileReplacePlaceholder') : $t('label.fileInputFilePlaceholder')"
                    :drop-placeholder="$t('label.fileInputDropPlaceholder')"
                    :browse-text="$t('label.browse')"
                    accept="
                    application/pdf,
                    video/x-msvideo,
                    image/bmp,
                    application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                    image/gif,
                    image/jpeg,
                    video/x-matroska,
                    audio/mpeg,
                    video/mp4,
                    video/mpeg,
                    application/vnd.oasis.opendocument.spreadsheet,
                    application/vnd.oasis.opendocument.text,
                    image/png,
                    application/vnd.ms-powerpoint,
                    application/vnd.openxmlformats-officedocument.presentationml.presentation,
                    image/svg+xml,
                    application/x-tar,
                    text/plain,
                    text/csv,
                    image/webp,
                    application/vnd.ms-excel,
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    application/zip,
                    application/x-7z-compressed
                    "
                ></b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              sm="2"
            >
              <b-button
                  v-if="item.file"
                  variant="danger"
                  size="sm"
                  class="float-left mr-1"
                  :disabled="!item.file"
                  @click.prevent="() => {item.file = null}"
              >
                <feather-icon
                    icon="Trash2Icon"
                    size="15"
                />
              </b-button>
              <b-button
                  v-if="!item.saved"
                  variant="success"
                  size="sm"
                  class="float-left"
                  :disabled="!item.file"
                  @click.prevent="uploadFile(index)"
              >
                <feather-icon
                    icon="ShareIcon"
                    size="15"
                />
              </b-button>
              <b-button
                  v-if="item.saved"
                  variant="success"
                  size="sm"
                  class="float-left"
                  :disabled="!item.file"
                  @click.prevent="changeFile(index)"
              >
                <feather-icon
                    icon="RefreshCwIcon"
                    size="15"
                />
              </b-button>
              <b-button
                  v-if="item.saved"
                  variant="danger"
                  size="sm"
                  class="ml-1"
                  @click.prevent="deleteFile(index)"
              >
                <feather-icon
                    icon="TrashIcon"
                    size="15"
                />
              </b-button>

              <b-link
                  v-if="item.saved"
                  :href="item.download_url"
                  target="_blank"
              >
                <b-button
                    variant="primary"
                    size="sm"
                    class="ml-1"
                >
                  <feather-icon
                      icon="DownloadIcon"
                      size="15"
                  />
                </b-button>
              </b-link>
            </b-col>
          </b-row>
        </b-form>
        </validation-observer>
      </b-overlay>
    </div>
  </b-tab>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BTab,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BOverlay,
  BForm,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTab,
    BBadge,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BOverlay,
    BForm,
    BLink,
  },
  props: {
    data: []
  },
  data() {
    return {
      loading: false,
      dangerCnt: 0,
      tabData: [],
    }
  },
  watch: {
    data: {
      handler(value) {
        this.tabData = value
        this.initTab()
      },
      deep: true
    }
  },
  computed: {
    isDisabledTab() {
      return !this.tabData.length
    }
  },
  created() {
    this.initTab()
  },
  methods: {
    initTab() {
      this.dangerCnt = 0
      if (this.tabData.length) {
        Object.keys(this.tabData).forEach(key => {
          if (!this.tabData[key]['saved']) {
            this.dangerCnt += 1
          }
        })
      }
    },
    uploadFile(index) {
      this.showLoadBar()
      const fd = new FormData()
      fd.append('customFileName', this.tabData[index].name)
      fd.append('blogPostId', this.$route.params.id)
      fd.append('file', this.tabData[index].file)

      this.$store.dispatch('storeFile', fd).then(() => {
        this.tabData[index].file = null
        this.$emit('refresh-data')
        this.$helpers.showSuccessToast()
      }).catch(error => {
        if (error.response.status === 422) {
          this.$helpers.showErrorToast(error.response.data.errors[0])
        } else if(error.response.status === 413){
          this.$helpers.showErrorToast(this.$t('text.api.response.tooBigImages'))
        }else{
          this.$helpers.showErrorToast(error.toString())
        }
      }).finally(() => {
        this.hideLoadBar()
      })
    },
    changeFile(index) {
      this.$bvModal
          .msgBoxConfirm(this.$t('modal.title.confirmReplaceFile'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('label.yes'),
            cancelTitle: this.$t('label.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.showLoadBar()

              const fd = new FormData()
              fd.append('blogPostId', this.$route.params.id)
              fd.append('fileId', this.tabData[index].id)

              this.$store.dispatch('deleteFile', fd)
                  .then(() => {
                    const fd = new FormData()
                    fd.append('customFileName', this.tabData[index].name)
                    fd.append('blogPostId', this.$route.params.id)
                    fd.append('file', this.tabData[index].file)

                    this.$store.dispatch('storeFile', fd).then(() => {
                      this.tabData[index].file = null
                      this.$emit('refresh-data')
                      this.$helpers.showSuccessToast()
                    }).catch(error => {
                      if (error.response.status === 422) {
                        this.$helpers.showErrorToast(error.response.data.errors[0])
                      } else if(error.response.status === 413){
                        this.$helpers.showErrorToast(this.$t('text.api.response.tooBigImages'))
                      }else{
                        this.$helpers.showErrorToast(error.toString())
                      }
                    }).finally(() => {
                      this.hideLoadBar()
                    })
                  }).catch(error => {
                if (error.response.status === 422) {
                  this.hideLoadBar()
                  this.$helpers.showErrorToast(error.response.data.errors[0])
                } else {
                  this.$helpers.showErrorToast(error.toString())
                }
              })
            }
          })
    },
    deleteFile(index) {
      this.$bvModal
          .msgBoxConfirm(this.$t('modal.title.confirmDeleteFile'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('label.yes'),
            cancelTitle: this.$t('label.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.showLoadBar()

              const fd = new FormData()
              fd.append('blogPostId', this.$route.params.id)
              fd.append('fileId', this.tabData[index].id)

              // törlés
              this.$store.dispatch('deleteFile', fd)
                  .then(() => {
                    this.tabData[index].file = null
                    this.$emit('refresh-data')
                    this.$helpers.showSuccessToast()
                  }).catch(error => {
                if (error.response.status === 422) {
                  this.$helpers.showErrorToast(error.response.data.errors[0])
                } else {
                  this.$helpers.showErrorToast(error.toString())
                }
              }).finally(() => {
                this.hideLoadBar()
              })
            }
          })
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
  }
}
</script>

<style scoped>

</style>
