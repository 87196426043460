<template>
  <b-tab>
    <template #title>
      <feather-icon
          icon="LinkIcon"
          size="21"
      />
      <strong>
        {{ $t('tab.title.relatedPosts') }}
      </strong>
    </template>
    <div>
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-row class="mb-5">
          <b-col sm="2">
            {{ $t('form.addRelatedPostToPost') }}
          </b-col>
          <b-col sm="4">
            <v-select
                v-model="selectedRelatedPost"
                label="title"
                :options="postOptions"
            />
          </b-col>
          <b-col sm="2">
            <b-button
                variant="primary"
                size="md"
                class="float-left"
                :disabled="!selectedRelatedPost"
                @click.prevent="addRelatedPost"
            >
              {{ $t('label.button.save') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <draggable
                v-model="tabData"
                class="list-group list-group-flush cursor-move"
                @change="updatePostsList"
            >
              <transition-group
                  type="transition"
                  name="flip-list"
              >
                <b-col
                    sm="12"
                    v-for="listItem in tabData"
                    :key="listItem.id"
                    :id="listItem.id"
                    class="dd-item mb-1 p-1"
                    style="border: 1px solid grey;"
                >
                  <b-row>
                    <b-col sm="1">{{ listItem.id }}</b-col>
                    <b-col :html="listItem.title" sm="4"/>
                    <b-col sm="6">{{ listItem.lead }}</b-col>
                    <b-col sm="1">
                      <b-button
                          variant="danger"
                          size="sm"
                          class="ml-1"
                          @click.prevent="deleteRelatedPost(listItem.id)"
                      >
                        <feather-icon
                            icon="TrashIcon"
                            size="15"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </transition-group>
            </draggable>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </b-tab>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import draggable from 'vuedraggable'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BTab,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BOverlay,
  BForm,
  BCardText,
  BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCardCode,
    draggable,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BBadge,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BOverlay,
    BForm,
    BCardText,
    BListGroupItem,
    vSelect,
  },
  props: {
    data: [],
    entityId: '',
    languageKey: ''
  },
  data() {
    return {
      loading: false,
      tabData: [],
      selectedRelatedPost: null,
      postOptions: [],
      language: '',
    }
  },
  watch: {
    data: {
      handler(value) {
        this.tabData = value
      },
      deep: true
    },
    languageKey(value) {
      this.language = value
      this.initData()
    }
  },
  methods: {
    initData() {
      this.$store.dispatch('fetchPostsForSelect', {language: this.language}).then(response => {
        let options = []
        Object.keys(response).forEach(key => {
          if (parseInt(response[key].value) !== parseInt(this.entityId)) {
            options.push(response[key])
          }
        })

        this.postOptions = options

        if (this.postOptions.length) {
          this.selectedRelatedPost = this.postOptions[0]
        }
      })
    },
    addRelatedPost() {
      this.showLoadBar()

      const fd = {
        'id': this.$route.params.id,
        'relatedId': this.selectedRelatedPost.value,
      }

      this.$store.dispatch('addRelatedPost', fd).then(() => {
        this.$emit('refresh-data')
        this.$helpers.showSuccessToast()
      }).catch(error => {
        if (error.response.status === 422) {
          this.$helpers.showErrorToast(error.response.data.errors[0])
        } else {
          if (error.response.data.message) {
            this.$helpers.showErrorToast(error.response.data.message)
          } else {
            this.$helpers.showErrorToast(error.toString())
          }
        }
      }).finally(() => {
        this.hideLoadBar()
      })
    },
    deleteRelatedPost(relatedId) {
      this.$bvModal
          .msgBoxConfirm(this.$t('modal.title.confirmDeleteRelatedPost'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('label.yes'),
            cancelTitle: this.$t('label.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const fd = {
                'id': this.$route.params.id,
                'relatedId': relatedId,
              }

              this.showLoadBar()

              // törlés
              this.$store.dispatch('deleteRelatedPost', fd)
                  .then(() => {
                    this.$emit('refresh-data')
                    this.$helpers.showSuccessToast()
                  }).catch(error => {
                if (error.response.status === 422) {
                  this.$helpers.showErrorToast(error.response.data.errors[0])
                } else {
                  this.$helpers.showErrorToast(error.toString())
                }
              }).finally(() => {
                this.hideLoadBar()
              })
            }
          })
    },
    updatePostsList() {
      const fd = {
        'id': this.$route.params.id,
        'list': this.tabData,
      }

      this.showLoadBar()

      // törlés
      this.$store.dispatch('updateRelatedPost', fd)
          .then(() => {
            this.$emit('refresh-data')
            this.$helpers.showSuccessToast()
          }).catch(error => {
        if (error.response.status === 422) {
          this.$helpers.showErrorToast(error.response.data.errors[0])
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      }).finally(() => {
        this.hideLoadBar()
      })
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.dd-item {
  transition: all 1s
}

.sortable-ghost {
  border: 1px solid green !important;
  background: #d1eed1;
}
</style>
