<template>
  <b-tab
      :disabled="isDisabledTab"
  >
    <template #title>
      <feather-icon
          icon="VideoIcon"
          size="21"
      />
      <strong>
        {{ $t('tab.title.videos') }}
        &nbsp<b-badge v-if="dangerCnt > 0" variant="danger">{{ dangerCnt }}</b-badge>
      </strong>
    </template>
    <div>
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <validation-observer ref="postVideoValidationObserver">
          <b-form>
            <b-row
                v-for="(item, index) in tabData"
                :key="item.id"
                sm="2"
                class="mb-1"
            >
              <b-col
                  sm="1"
              >
                {{ item.name }}
                &nbsp
                <feather-icon
                    v-if="item.saved"
                    icon="ShareIcon"
                    size="15"
                    class="text-success"
                />
              </b-col>
              <b-col
                  sm="9"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.video').toLowerCase()"
                    rules=""
                    vid="video"
                >
                  <b-form-input
                      v-model="item.value"
                      :placeholder="$t('form.videoID').toLowerCase()"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                    variant="primary"
                    size="sm"
                    class="float-left"
                    @click.prevent="refreshVideos"
                >
                  {{ $t('label.button.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </div>
  </b-tab>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BTab,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BOverlay,
  BForm,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTab,
    BBadge,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BOverlay,
    BForm,
  },
  props: {
    data: []
  },
  data() {
    return {
      loading: false,
      dangerCnt: 0,
      tabData: [],
    }
  },
  watch: {
    data: {
      handler(value) {
        this.tabData = value
        this.initTab()
      },
      deep:true
    }
  },
  computed: {
    isDisabledTab() {
      return !this.tabData.length
    }
  },
  created() {
    this.initTab()
  },
  methods: {
    initTab() {
      this.dangerCnt = 0
      if (this.tabData.length) {
        Object.keys(this.tabData).forEach(key => {
          if (!this.tabData[key]['saved']) {
            this.dangerCnt += 1
          }
        })
      }
    },
    refreshVideos() {
      this.showLoadBar()
      const fd = {
        'id': this.$route.params.id,
        'videos': this.tabData,
      }

      this.$store.dispatch('updatePostVideos', fd).then(() => {
        this.$emit('refresh-data')
        this.$helpers.showSuccessToast()
      }).catch(error => {
        if (error.response.status === 422) {
          this.$helpers.showErrorToast(error.response.data.errors[0])
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      }).finally(() => {
        this.hideLoadBar()
      })
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
  }
}
</script>

<style scoped>

</style>
