<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.post') }}
            <div class="float-right mr-1">
              <b-button
                  variant="danger"
                  size="sm"
                  @click="deleteEntity"
              >
                <span v-if="!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution()"
                      class="text-nowrap">{{ $t('label.button.delete') }}</span>
                <feather-icon v-else icon="XCircleIcon"/>
              </b-button>
            </div>
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.post') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.title')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.title').toLowerCase()"
                      rules="required"
                      vid="title"
                  >
                    <b-form-input
                        v-model="formData.title"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.title').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.status')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.status').toLowerCase()"
                      rules="required"
                      vid="status"
                  >
                    <v-select
                        v-model="formData.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="statusOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.category')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.category').toLowerCase()"
                      rules="required"
                      vid="status"
                  >
                    <v-select
                        v-model="formData.category"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="categoryOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    :label="$t('form.tags')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.tags').toLowerCase()"
                      vid="tags"
                  >
                    <v-select
                        v-model="formData.tags"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        multiple
                        push-tags
                        :options="tagOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    :label="$t('form.lead')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.lead').toLowerCase()"
                      rules="required"
                      vid="lead"
                  >
                    <b-form-textarea
                        v-model="formData.lead"
                        rows="10"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    :label="$t('form.postContent')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.postContent').toLowerCase()"
                      rules="required"
                      vid="content"
                  >
                    <b-link
                        @click="$bvModal.show('bv-modal-post-help')"
                    >
                      <small class="text-primary cursor-pointer">* "Speciális jelölések" súgó</small>
                    </b-link>
                    <b-form-textarea
                        v-model="formData.content"
                        rows="20"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    :label="$t('form.visibleFrom')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.visibleFrom').toLowerCase()"
                      rules=""
                      vid="visibleFrom"
                  >
                    <flat-pickr
                        v-model="formData.visibleFrom"
                        class="form-control"
                        :config="flatPickRConfig"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    :label="$t('form.visibleTo')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.visibleTo').toLowerCase()"
                      rules=""
                      vid="visibleTo"
                  >
                    <flat-pickr
                        v-model="formData.visibleTo"
                        class="form-control"
                        :config="flatPickRConfig"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.sticky')"
                    vid="sticky"
                >
                  <b-form-checkbox
                      v-model="formData.sticky"
                      :state="errors.length > 0 ? false:null"
                  >
                    {{ $t('form.sticky') }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>

              <b-col :md="12" class="mt-1">
                <button class="btn btn-flag" type="button" v-for="t in translations" v-if="t.code !== formData.language"
                        @click="editTranslation(t)">
                  <img class="flag-icon" :class="{'existing': t.id !== null}" v-if="t.code !== 'uk' && t.code !== 'en' && t.code !== 'et' && t.code !== 'da' && t.code !== 'lb'" :src="'https://flagicons.lipis.dev/flags/4x3/'+t.code+'.svg'" width="30" :alt="t.name" :title="t.name" />
                  <img class="flag-icon" :class="{'existing': t.id !== null}" v-if="t.code === 'uk'" :src="'https://flagicons.lipis.dev/flags/4x3/ua.svg'" width="30" :alt="t.name" :title="t.name" />
                  <img class="flag-icon" :class="{'existing': t.id !== null}" v-if="t.code === 'en'" :src="'https://flagicons.lipis.dev/flags/4x3/gb.svg'" width="30" :alt="t.name" :title="t.name" />
                  <img class="flag-icon" :class="{'existing': t.id !== null}" v-if="t.code === 'et'" :src="'https://flagicons.lipis.dev/flags/4x3/ee.svg'" width="30" :alt="t.name" :title="t.name" />
                  <img class="flag-icon" :class="{'existing': t.id !== null}" v-if="t.code === 'da'" :src="'https://flagicons.lipis.dev/flags/4x3/dk.svg'" width="30" :alt="t.name" :title="t.name" />
                  <img class="flag-icon" :class="{'existing': t.id !== null}" v-if="t.code === 'lb'" :src="'https://flagicons.lipis.dev/flags/4x3/lu.svg'" width="30" :alt="t.name" :title="t.name" />
                </button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <help-modal/>
      </b-card-code>
    </b-overlay>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            @refresh-tabs-data="refreshTabsData"
            :attributes="this.tabsProperties"
            :entity-id="parseInt(this.$route.params.id)"
            :language-key="formData.language"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BOverlay,
  BLink,
  BFormTextarea
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import helpModal from '@/views/pages/_components/_modal/post/help.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'
import tabs from '@/views/pages/_components/_tabs/_post/update.vue'

export default {
  components: {
    BFormTextarea,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    BLink,
    vSelect,
    cancelAndGOTOPreviousPage,
    helpModal,
    flatPickr,
    tabs
  },
  data() {
    return {
      role: 'post',
      formData: {
        id: null,
        tags: [],
        category: '',
        title: '',
        lead: '',
        content: '',
        visibleFrom: '',
        visibleTo: '',
        status: '',
        sticky: false,
      },
      type: 'post',
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      tabsProperties: {
        images: [],
        videos: [],
        files: [],
        relatedPosts: [],
      },
      categoryOptions: [],
      statusOptions: [],
      tagOptions: [],
      translations: [],
      tabsLoading: false,
      loading: false,
      required,
      email,
    }
  },
  created() {
    this.initPost()
  },
  methods: {
    initPost(onlyTabs = false) {
      this.$store.dispatch('fetchPost', this.$route.params.id).then(response => {
        this.processPost(response.data.entity, onlyTabs)
      })
    },
    processPost(post, onlyTabs = false) {
      this.tabsLoading = true

      if (!onlyTabs) {
        this.loading = false
        this.$store.dispatch('fetchPost', this.$route.params.id).then(response => {
          const entity = response.data.entity
          this.formData = entity
          this.formData.content = entity.post_content
          this.formData.visibleFrom = entity.visible_from
          this.formData.visibleTo = entity.visible_to
          this.formData.id = this.$route.params.id

          this.$store.dispatch('fetchPostStatuses').then(sResponse => {
            this.statusOptions = sResponse.data.values

            if (this.statusOptions.length) {
              Object.keys(this.statusOptions).forEach(key => {
                if (this.statusOptions[key].value === entity.status) {
                  this.formData.status = this.statusOptions[key]
                }
              })
            }
          })

          this.$store.dispatch('fetchCategoriesForSelect', {language: this.formData.language}).then(cResponse => {
            this.categoryOptions = cResponse

            if (this.categoryOptions.length) {
              Object.keys(this.categoryOptions).forEach(key => {
                if (this.categoryOptions[key].value === entity.blog__category_id) {
                  this.formData.category = this.categoryOptions[key]
                }
              })
            }
          })

          let selectedTags = []

          this.$store.dispatch('fetchTagsForSelect', {language: this.formData.language}).then(tResponse => {
            this.tagOptions = tResponse

            if (this.tagOptions.length && entity.tags && entity.tags.length) {
              Object.keys(entity.tags).forEach(tKey => {
                Object.keys(this.tagOptions).forEach(tOKey => {
                  if (entity.tags[tKey].id === this.tagOptions[tOKey].value) {
                    selectedTags.push(this.tagOptions[tOKey])
                  }
                })
              })
            }

            this.formData.tags = selectedTags
          })

          let languageParentId;
          if (entity.language_parent_id === null) {
            languageParentId = entity.id
          } else {
            languageParentId = entity.language_parent_id
          }

          this.$store.dispatch('fetchPostTranslations', languageParentId).then(tResponse => {
            this.translations = tResponse.data.translations
          })
        }).finally(() => {
          this.loading = false
        })
      }

      this.tabsProperties = {
        images: [],
        videos: [],
        files: [],
        relatedPosts: [],
      }

      if (post.parsed.paragraphs.length) {
        let tmp = []
        Object.keys(post.parsed.paragraphs).forEach(key => {
          tmp = [post.parsed.paragraphs[key]['data']]

          if (post.parsed.paragraphs[key]['type'] === 'image') {
            this.tabsProperties.images = [...this.tabsProperties.images, ...tmp]
          }

          if (post.parsed.paragraphs[key]['type'] === 'video') {
            this.tabsProperties.videos = [...this.tabsProperties.videos, ...tmp]
          }
        })
      }

      // fileok kezelése
      if (post.parsed.files.length) {
        let tmp = []
        Object.keys(post.parsed.files).forEach(key => {
          tmp = [post.parsed.files[key]['data']]
          this.tabsProperties.files = [...this.tabsProperties.files, ...tmp]
        })
      }

      if (post.parsed.relatedPosts.length) {
        this.tabsProperties.relatedPosts = post.parsed.relatedPosts
      }

      this.tabsLoading = false
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.status = this.formData.status.value
          fd.blogCategoryId = this.formData.category.value

          this.$store.dispatch('updatePost', fd).then(() => {
            this.$helpers.showSuccessToast()
            this.initPost()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    refreshTabsData() {
      this.initPost(true)
    },
    deleteEntity() {
      this.$bvModal
          .msgBoxConfirm(this.$t('confirm.message.delete'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
            cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const id = this.$route.params.id
              this.$store.dispatch('deletePost', id).then(() => {
                this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id}))

                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'posts'})
                    : this.$router.go(-1)
              })
            }
          })
    },
    async editTranslation(translation) {
      if (translation.id !== null) {
        await this.$router.push({name: 'modify-post', params: {id: translation.id}}).then(() => {
          this.initPage()
          this.$helpers.showSuccessToast()
        })
      } else {
        this.$store.dispatch('translateEntity', {
          id: this.formData.id,
          type: this.type,
          lang: translation.code
        }).then(async response => {
          await this.$router.push({name: 'modify-' + this.type, params: {id: response.data.entity.id}}).then(() => {
            this.initPage()
            this.$helpers.showSuccessToast()
          })
        }).catch(error => {
          if (error.response.status === 422) {
            this.$refs.simpleRules.setErrors(error.response.data.errors)
          } else {
            this.$helpers.showErrorToast(error.toString())
          }
        })
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


.btn-flag {
  padding: 0 5px;
  background: transparent;
}

.flag-icon {
  border: 1px solid black;
}

.flag-icon.existing {
  border: 3px solid #28c76f;
}
</style>
